.footer {
    background-color: #f5f7fc;
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center
}

.footer-logo {
    width: 50%
}

.footer p {
    font-size: 18px;
    color: #6f6c90
}

.icons {
    display: flex;
    gap: 30px
}

.icons a {
    color: #52515d
}

.icons a:hover {
    color: #170f49
}

.footer h5 {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 25px
}

.footer ul {
    list-style: none;
    margin: 0 !important;
    padding: 0 !important
}

.footer a {
    text-decoration: none;
    color: #6f6c90;
    line-height: 45px;
    font-size: 18px
}

.footer a:hover {
    text-decoration: underline
}

.footer span {
    margin-left: 5px
}

.terms-condtion {
    display: flex;
    height: 80px;
    background-color: #170f49;
    width: 100%;
    align-items: center;
    justify-content: center;
    color: #cbcadc
}

.terms-condtion .footer-bottom {
    margin-bottom: 0;
    font-size: 16px
}

.contact-data {
    margin-bottom: 15px;
    display: flex !important;
    align-items: start;
    gap: 10PX
}

@media (max-width:996px) {
    .footer {
        height: 100%
    }

    .footer .col-12 {
        margin: 25px 0;
        width: 50%;
        text-align: center
    }

    .contact-data {
        justify-content: center
    }

    .icons {
        display: flex;
        justify-content: center;
        gap: 10px
    }

    .footer a {
        line-height: 35px
    }

    .footer br {
        display: block !important
    }
}

@media (max-width:576px) {
    .footer br {
        display: block !important
    }

    .footer {
        height: 100%;
        display: flex;
        justify-content: center
    }

    .footer .col-12 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 25px 0;
        text-align: center
    }

    .contact-data {
        display: flex;
        justify-content: center;
        padding: 0 45px;
    }

    .footer a {
        line-height: 35px
    }

    .footer svg {
        width: 35px
    }

    .terms-condtion .footer-bottom {
        font-size: 14px;
        text-align: center;
        
    }
}