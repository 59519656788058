

.Whats-app {
    bottom: 50px;
    right: 50px;
    z-index: 9999;

    background: #25d366;
    color: white;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 0;
    padding:35px;
    text-decoration: none;
    border-radius: 50%;
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }

    80% {
        box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
    }
}
@media (max-width:576px) {
    .Whats-app {
        right: 30px;
        padding: 30px;
    }
    .Whats-app svg{
        height: 40px;
        width: 40px;
    
        
    }
    
}